@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in-transition {
    animation: fade-in 0.3s ease-in;
}